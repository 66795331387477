import React from "react";
import style from "./button_to_up.module.scss";

function ButtonToUp() {
  return (
    <a href="#" className={style.to_up_button}>
      <div className={style.to_up_button_loading_div}>
        <div className={style.to_up_button_loading}>
          <div className={style.to_up_button_loading_before}></div>
        </div>
        <img
          alt="logo-to-up"
          src="./assets/icons/button_to_up.svg"
          width={50}
          height={50}
          className={style.to_up_button_loading_image}
        />
      </div>
    </a>
  );
}

export default ButtonToUp;
