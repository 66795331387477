import React from "react";
import style from "./notFound.loading.module.scss";

function NotFound({ error }) {
    return (
        <div className={style.loading}>
            <div className={style.loading_div}>
                <div className={style.loading}>
                    <div className={style.loading_before}></div>
                </div>
                {!error ? (
                    <img
                        src="./assets/icons/loading-logo.webp"
                        className={style.loading_image}
                        alt="loading"
                    />
                ) : (
                    <h1 className={style.error_page}>{error}</h1>
                )}
            </div>
        </div>
    );
}

export default NotFound;
