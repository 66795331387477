import React from "react";
import style from "./footer.module.scss";

function Footer() {
  const scrollToHeader = () => {
    const header = document.getElementById('logo');
    if (header) {
      header.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <footer className={style.footer_style}>
      <a href="#logo" onClick={scrollToHeader} rel="noopener noreferrer">
        © primeIT company llc 2024
      </a>
      <span>all rights reserved</span>
    </footer>
  );
}

export default Footer;
