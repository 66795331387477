import React, { useEffect, useState } from "react";
import style from "./timeline.module.scss";

function TimeLine() {
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const [usTime, setUsTime] = useState(
    new Date().toLocaleTimeString("en-US", {
      ...timeOptions,
      timeZone: "America/New_York",
    })
  );

  const [armeniaTime, setArmeniaTime] = useState(
    new Date().toLocaleTimeString("en-US", {
      ...timeOptions,
      timeZone: "Asia/Yerevan",
    })
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setUsTime(
        new Date().toLocaleTimeString("en-US", {
          ...timeOptions,
          timeZone: "America/Los_Angeles",
        })
      );
      setArmeniaTime(
        new Date().toLocaleTimeString("en-US", {
          ...timeOptions,
          timeZone: "Asia/Yerevan",
        })
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <>
      <div className={style.timeline_block}>
        <div className={style.email_phone}>
          <div className={style.email_phone_line}>
            <img
              alt=""
              src="/assets/icons/email_icon.svg"
              width={20}
              height={20}
            />
            <span>prime@primeit.dev</span>
          </div>
          <div className={style.email_phone_line}>
            <img
              alt=""
              src="/assets/icons/phone_icon.svg"
              width={20}
              height={20}
            />
            <span>+37495583042</span>
          </div>
        </div>
        <div className={style.countr_title}>
          <h3>
            USA <span> , CA , Los Angeles</span>
          </h3>
          <h3>
            Armenia <span> , Yerevan</span>
          </h3>
        </div>
        <div className={style.flag_time}>
          <div className={style.flag_usa}>
            <img src="/assets/icons/usa_flag.png" alt="usa flag" />
          </div>
          <div className={style.time}>
            <div className={style.usa_time}>{usTime}</div>
            <div className={style.am_time}>{armeniaTime}</div>
          </div>
          <div className={style.flag_usa}>
            <img src="/assets/icons/am_flag.png" alt="usa flag" />
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeLine;
