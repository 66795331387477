import React, { useEffect, useState } from "react";
import "./App.scss";
import Layout from "./components/layout/layout";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import NotFound from "./components/notFound/NotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Layout />} />
            <Route path="*" element={<NotFound error="Error 404" />} />
      </Routes>
    </Router>
  );
}
export default App;
