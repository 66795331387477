import React, { useEffect, useState } from "react";
import style from "./layout.module.scss";
import Header from "./../header/header";
import Footer from "../footer/footer";
import Form from "./../form/form";
import Portfolio from "./../portfolio/portfolio";
import Loading from "./../loading/loading";

import ButtonToUp from "../button_to_up/button_to_up";
import { Element } from "react-scroll";
import TimeLine from "../timeline/timeline";

function Layout() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sign = localStorage.getItem("sign")
      ? JSON.stringify(localStorage.getItem("sign"))
      : null;

    if (!sign) {
      setIsLoading(true);

      setTimeout(() => {
        console.log("set-time__3000");
        localStorage.setItem("sign", true);
        setIsLoading(false);
      }, 2800);
    } else {
      console.log("else when again signin");
      setIsLoading(true);

      setTimeout(() => {
        console.log("set-time__1000");
        setIsLoading(false);
      }, 1000);
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <main className={style.layout_body}>
          <h1 className={style.visually_hidden}>Prime IT </h1>
          <h2 className={style.visually_hidden}>Prime IT Web Development</h2>
          <div className={style.layout_layout}>
            <Element name="hero" className="element" id="section1">
              <section className={style.hero_section}>
                <video
                  autoPlay
                  muted
                  loop
                  loading="lazy"
                  className={style.hero_section_video}
                >
                  <source src="./assets/images/prime.webm" type="video/webm" />
                  <track
                    src="./assets/images/hero.webp"
                    kind="caption"
                    srcLang="en"
                    label="background_photo"
                  />
                </video>
                <Header />
                <div className={style.hero_section_next}>
                  <div className={style.herosection_text_form}>
                    <h2 className={style.hero_section_text}>
                      Let’s start a Project Together
                    </h2>
                    <Form />
                  </div>
                  <div className={style.time_line_and_flash_sale}>
                    <div>
                      <TimeLine />
                    </div>
                    <div>{/* <FlashSale /> */}</div>
                  </div>
                  <img
                    src="/assets/icons/innovative.svg"
                    alt="hero_section_inovate"
                    width={"95%"}
                    height={"auto"}
                    className={style.hero_section_inovate}
                  />
                </div>
              </section>
            </Element>
            <Element name="portfolio" className="element" id="section2">
              <Portfolio />
            </Element>
          </div>
          <Footer />
          <ButtonToUp />

          <p className={style.visually_hidden}>
            Custom software development servicess, Professional web development
            company, Innovative UI/UX design solutions, React Native mobile app
            development, Enterprise IT solutions provider, Agile software
            development firm, Cloud computing and SaaS services, IT project
            outsourcing services, Advanced cybersecurity solutions for
            businesses, E-commerce development experts, Digital transformation
            consultancy, Full-stack development services, Artificial
            intelligence application development, Blockchain technology
            solutions company, Tech startup development partner Discover PRIME
            IT, your premier partner for 'cutting-edge digital transformation
            services' and 'advanced cloud computing solutions'. Specializing in
            'custom software development' and 'next-gen mobile app creation', we
            harness the power of 'AI-driven analytics' and 'blockchain
            technology' to propel your business forward. Our expert team
            delivers 'comprehensive cybersecurity measures', 'scalable SaaS
            platforms' / Software as a service, and 'innovative IoT
            applications', ensuring 'sustainable tech growth' for startups to
            enterprises alike. Choose PRIME IT for a 'future-proof IT strategy'
            that sets you apart in the digital landscape.
          </p>
        </main>
      )}
    </div>
  );
}

export default Layout;
